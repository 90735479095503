.signup {

    &--title {
        font-weight: 500;
        font-size: 40px;
        margin: 0;
        padding-bottom: 20px;
        margin-bottom: 30px;
        width: 100%;
        border-bottom: 1px solid #EFEFFF;
    }

    &--oauth-buttons {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
    }

    &--divider {
        margin: 0;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        position: relative;
        text-align: center;
        overflow: hidden;

        &:before,
        &:after {
            content: "";
            width: 50%;
            height: 1px;
            position: absolute;
            top: 51%;
            background-color: #efefff;
        }

        &:before {
            margin-left: -53%;
        }

        &:after {
            margin-left: 3%;
        }
    }

    &--form {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 20px;
        width: 100%;
    }

    &--login {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 40px;
        width: 100%;

        .sign-up-link {
            font-style: normal;
            color: #5B93FF;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}