.explanation-balance {
    display: flex;
    flex-direction: column;
    gap: 30px;

    >table {
        border-radius: 10px;
        width: 100%;
        border-spacing: 0;
        background: var(--card-bg);
        border: var(--border-primary);
        overflow: hidden;

        >thead {
            background: #374263;
            color: #fff;

            >tr {
                >th {
                    text-align: end;
                    padding: 10px;
                    border-left: 1px solid #68677F;
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    padding: 0;

                    >.values {
                        height: 55px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: center;
                        border-right: var(--border-primary);
                        padding-right: 10px;

                        p {
                            &.value {
                                color: #818094;
                            }
                        }
                    }
                }

                &.title-row {
                    background: #EFEFFF;

                    >td {
                        padding: 7px 10px;
                    }
                }
            }
        }
    }
}