.company-settings-title {
  > .company-name {
    color: #fff;
  }

  > .company-number {
    color: #818094;
  }
}

.company-settings-popup {
  display: flex;
  min-height: 600px;
  position: relative;

  > .navigation {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    border-right: var(--border-primary);

    > .nav-item {
      padding: 10px 15px;
      text-align: start;
      background: #fff;
      border: none;
      cursor: pointer;
      border-bottom: var(--border-primary);

      &.active {
        background: #efefff;
      }

      &:hover {
        background: #efefff;
      }
    }
  }

  > .content {
    width: 100%;
    display: none;
    flex-direction: column;

    &.active {
      display: flex;
    }

    > .header {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 15px;
      background-color: #f8f8f8;
      color: #818094;

      > .actions {
        display: flex;
        align-items: center;
        gap: 10px;
        > .back-button {
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
          max-width: 30px;
          max-height: 30px;
          border-radius: 50%;
          background: #818094;
          color: #fff;
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          -webkit-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          vertical-align: middle;
          z-index: 1;
          transition: 0.3s ease-out;
        }
      }
    }

    > .add-card-form {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 20px;
      > .input-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 15px;
        > .input-group-row,
        > .input-group-grid {
          width: 100%;
          display: flex;
          gap: 10px;
        }

        > .input-group-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 10px;

          > .input {
            outline: none;
            padding: 10px 15px;
            border-radius: 40px;
            background: #fff;
            border: 1px solid #a6a6af;
            color: #000d33;
            width: 100%;
          }
        }
        > .input {
          outline: none;
          padding: 10px 15px;
          border-radius: 40px;
          background: #fff;
          border: 1px solid #a6a6af;
          color: #000d33;
          width: 100%;
        }
      }
      > .button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;
        background-color: #f8f8f8;
      }
    }

    > .company-settings-row,
    > .company-settings-col {
      border-bottom: var(--border-primary);
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      > .checkboxes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100px;
      }

      > .accounting-firm {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    > .card-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      gap: 20px;
    }

    > .company-settings-col {
      flex-direction: column;
      border: none;

      > .input-group {
        display: flex;
        align-items: flex-end;
        width: 100%;
        gap: 10px;
      }
    }

    > .save-settings {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      margin-top: auto;
    }
  }
}
