.key-values {
    display: flex;
    flex-direction: column;
    gap: 50px;

    >.controls {
        display:flex;
        justify-content: space-between;

        >.dropdowns {
           
        display: flex;
        align-items: center;
        gap: 20px;
       

        }
    }
}

