.notice-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  > .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 20px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    > .title {
      color: #000d33;
    }
  }
  > .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    gap: 20px;
    flex-grow: 1;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    > .title {
      color: #818094;
    }

    > .plus-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;

      width: 30px;
      height: 30px;

      background: #000d33;
      color: #ffffff;
      border-radius: 40px;
    }

    > .more-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      background: transparent;
      color: #818094;
    }

    > .input-wrapper {
      display: flex;
      flex-direction: row;
      border-radius: 40px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 6.5px;
      padding-bottom: 6.5px;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      border: var(--border-primary);

      > .input-field {
        width: 50px;
        margin: 0px;
        padding: 0px;
        outline: none;
        border: none;
      }
    }
  }
}
