@import "../../styles/circle.scss";
.health {
  > .automatic-notifications {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: var(--border-primary);
    background: #fff;
    margin-top: 50px;

    > .top {
      background: #4f4e69;
      color: #fff;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      > .icon-btn {
        width: 13px;
        height: 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        color: #818094;
      }
    }

    > .content {
      > .section-row {
        width: 100%;
        border-bottom: var(--border-primary);
        display: flex;

        &:last-child {
          border-bottom: none;
        }

        > .section {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: row;
          border-right: var(--border-primary);
          padding: 30px 20px;
          gap: 15px;
          color: #000d33;

          &:last-child {
            border-right: none;
          }
          > .content {
            gap: 15px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            > .values {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              padding: 0px;
              gap: 30px;

              > .value {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 5px;

                > .title {
                  color: #818094;
                }

                > .chips {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 10px;

                  > .chip {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px 15px;
                    gap: 10px;
                    border: 1px solid #efefff;
                    border-radius: 40px;
                    height: 30px;
                  }
                }
              }
            }
          }

          > .circle-chart {
            height: 123px;
            width: 123px;
          }

          > img {
            @include circle(105px);
          }
        }
      }
    }
  }

  > .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    border: var(--border-primary);
    border-radius: 10px;
    background: #fff;
    margin-top: 30px;

    > .notice-row {
      border-bottom: var(--border-primary);
    }
  }

  > .header {
    margin-top: 50px;
  }

  > .controls {
    display: flex;
    justify-content: flex-end;

    > .dropdowns {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
