.bank-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 15px;
  height: 97px;
  width: 100%;

  background: #ffffff;

  border: 1px solid #efefff;
  border-radius: 10px;

  > .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 50px;
    height: 18px;
    width: 100%;

    > .card-icon {
      width: 27px;
      height: 18px;
      border-radius: 2px;
    }

    > .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 0px;
      gap: 10px;
      height: 12px;

      > .icon {
        cursor: pointer;
        border: none;
        background: transparent;
        width: 12px;
        height: 12px;

        > .active {
          color: #000d33;
        }
        > .delete {
          color: #ff0000;
        }
      }
    }
  }

  > .bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 70px;

    > .item {
      display: flex;
      flex-direction: column;
      > .label {
        color: #a6a6af;
      }
      > .value {
        text-transform: capitalize;
        color: #000d33;
      }
    }
  }
}
